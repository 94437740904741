export const navbarItems = ["home", "paintings", "about", "contact"];
export const paintingsSubMenu = [
  "paintings",
  "linocuts",
  "smaller-works",
  "patterns",
];
export const aboutSubMenu = ["statement", "CV", "press"]; // "photos"

export const homeTitle = "Astrid Slettevold's Art";
export const homeText = [
  "This is a presentation of my art",
  "I find inspiration in many places",
  "I am facinated by music, literature, film, and the ordinary",
  "Patterns are more enjoyable when not obviously predetermined",
  "Often, I rebel against themes, techniques, and setups",
  "Art is freedom 🙂",
];

export const footerText = "Astrid Slettevold | ast-art.no";
export const instagramLink = "https://www.instagram.com/astarte123/?hl=nb";
export const facebookLink = "https://www.facebook.com/groups/554277421276425/";
export const linkedInLink =
  "https://no.linkedin.com/in/astrid-slettevold-47921330";
export const atelieLink = "https://atelie.art/atelier/astrid-slettevold-ast";

export const statementTitle = "Artist's statement";
export const statementText = [
  "Expressing myself through color and visual elements gives me a more meaningful life. I don't always know what " +
    "I'm searching for, but the process toward something 'optimal' seems like a guiding principle to get to know myself " +
    "better. It can be difficult to understand how the creative process unfolds and why. Often, I experiment with " +
    "different expressions and discard them if an immediate connection or communication doesn't occur.",
  "I find inspiration in many places, but I am always fascinated by music, literature, and films with unpredictable " +
    "narratives. I work extensively to bring out unique and condensed color combinations, and this work can be a slow " +
    "adjustment process. Older colorists like Gauguin and Nesterov always provide me with good impulses, but I also " +
    "find some contemporary art stimulating. For me, the goal is to create a painting that can evoke wonder, and the " +
    "expression can often be 'slow-paced' and mysterious.",
  "My approach to the work depends on the format I am working with. The pattern works are relatively large, and " +
    "here I first set up an underlying grid/repeat. Sometimes I paint over the entire surface so that only small " +
    "traces remain, and other times the focus is on the composed pattern - but deviations are always exciting! The " +
    "material here is canvas, which I eventually stretch onto a frame.",
  "The multicolored linocuts are carefully planned before starting. The challenge here is to make form and colors " +
    "work together. The expression usually consists of 3 or 4 plates. These works are narratives with a clear " +
    "figurative visual language.",
  "In the smaller paintings on paper, the process is different - here I work entirely intuitively throughout the " +
    "process, following thoughts and ideas that come up at the moment.",
  "Many find it confusing to work with different expressions, but for me, it's a good thing because I always feel " +
    "renewed and stimulated when I return to a specific form of expression after a period of absence.",
];

// CV
export const educationTitle = "Education";
export const educationText = [
  "Oslo University, Department of EST; Master's Degree 1994 - 1996",
  "Art History, University of Oslo (UiO) 1983",
  "State Teacher Training College in Design, Notodden 1978 - 1979",
  "Aarhus Academy of Fine Arts 1975 - 1978",
];
export const artJobsTitle = "Art jobs";
export const artJobsText = [
  "Teacher in Design Subjects, Bredtvet Upper Secondary School, 1998 - 2008",
  "Shop Assistant, Purchasing Manager, Head of Department, KEM 1981 - 1994",
];
export const soloExhibitionsTitle = "Solo exhibitions";
export const soloExhibitionsText = [
  "Ski Kunstforening 2024",
  "Galleri Midtstuen, Rakkestad Kunstforening 2023",
  "Galleri Syningen, Ål 2023",
  "Galleri Gamle Eidsfos, 2022",
  "Lørenskog Kunstforening, 2022",
  "Galleri 1845, Gml Fr stad 2020",
  "Galleri DC-3, Fornebu 2019",
  "Rælingen Kunstforening, 2019",
  "Galleri Albin-Art, Oslo 2018",
  "Galleri ArtToBe, Tomter 2018",
  "Galleri Magenta, Gamle Fredrikstad 2018",
  "Galleri ØKS, Fredrikstad 2017",
  "Holmsbustuene, Holmsbu 2017",
  "Galleri Odonata, Fornebu 2016",
  "Dalype Galleri, Oslo 2016",
  "Åsnes Kunstforening, Christmas Exhibition 2015",
  "Galleri Emilie, Skjærhalden Hvaler 2015",
  "Galleri Clara, Gamle Fredrikstad 2014",
  "Galleriverkstedet, Oslo 2013",
  "Galleri S9, Oslo 2013",
  "Galleri Fargekolonien, Herføl Hvaler 2011",
  "Galleri Krypten, Kongsvinrger 2010",
  "Galleri Gjøken, Oslo 2010",
  "Restaurant Villa Sanvigen, Svartskog 2010",
  "Galleri BOA, Oslo 2009",
  "Café Pilestedet, Oslo 2008, 2009",
  "Galleri Fjøset 2008",
  "Dalype Galleri, Oslo 2003, 2005, 2016",
  "Mira Boat Gallery, Alcânterra PT 2005",
  "Rakkestad Kunstforening, Rakkestad 2004",
  "Husets Galleri, Århus 1978, 1986",
];
export const groupExhibitionsTitle = "Group exhibitions (selected)";
export const groupExhibitionsText = [
  "Elverum Kunstforening 2023",
  "Galleri Farsund, Farsund 2023",
  "Galleri Floren, Skjærhalden Hvaler 2023",
  "Galleri DC-3, Forneburingen 2023",
  "Romerike Exhibition 2016, 2017, 2021, 2023",
  "ØKS, Fredrikstad Summer Exhibition 2010 - 2023",
  "Modum Kunstforening, Christmas Market 2022",
  "Galleri Åpent, Skjærhalden Hvaler 2022",
  "Telemark Exhibition 2015, 2022",
  "Galleri Magenta, Gamle Fr stad 2021",
  "Galleri ArtToBe, Tomter Artist of the Month 2021",
  "Nittedal Kunstforening, Christmas Exhihibition 2021",
  "H12, Summer/Winter Exhibition, Oslo 2015 - 2021",
  "Ål Kunstforening, Christmas Exhibition 2020",
  "Rælingen Kunstforening, Winter Exhibition 2020",
  "Galleri 'Fjøllhalli', Rauland 2018",
  "Galleri Strømsfoss Mølle, Aremark 2010 - 2018",
  "Miniature Exhibition '2LITTLE', Michigan, USA 2015",
  "Eidsberg Kunstforening, Mysen 2015",
  "Gallery NOoSphere, (Anniversary) NY, USA 2014",
  "Galleri Fargekolonien, Herføl Hvaler 2013",
  "Galleri Straume, Vinje 2012",
  "Galleri Adamstuen, ArtFusion, Oslo 2008",
  "Galleri Gamle Tomb, Råde  2005",
  "Østland Exhibition, Lillestrøm 2002",
  "Oslo Art Association (Tendens 1990), Oslo 1990",
  "New York's Atelié, Oslo 1984",
  "Galleri 7, Oslo 1984",
  "Galleri UKS, Oslo 1982",
  "Vestfold Exhibition, Tønsberg 1981",
];
export const grantsTitle = "Grants";
export const grantsText = ["Grant from the Norwegian Arts Council 2018"];
export const publicPurchasesTitle = "Public purchases";
export const publicPurchasesText = [
  "Norwegian Library Association 2023",
  "Trondheim Municipality 2011",
  "Aremark Municipality 2011",
];
export const consultancyTitle = "Consultancy";
export const consultancyText = [
  "Galleri Strømsfoss Mølle, Coordinator for Summer Exhibitions 2010 - 2014, 2016 - 2018",
  "Sirkel Magazine, About paper surfaces 2014",
  "Galleri OBK, 'A 6-Year-Old's Visual World' 1996",
  "Kunst for Alle Magazine, Regular column writer 1994 - 1998",
];
export const membershipsTitle = "Memberships";
export const membershipsText = [
  "Norske Billedkunstnere (NBK)",
  "Østfold Kunstsenter (ØKS)",
];
export const commissionsTitle = "Projects";
export const commissionsText = [
  "Decor of a soundbox on a harpsichord, 2015",
  "Illustrations for the Directorate of Public Roads' report 1995",
];
export const positionsTitle = "Positions";
export const positionsText = [
  "Nomination Committee and Deputy Member in KEM BA 2007 - 2017",
  "Nomination Committee BOA 2011 - 2012",
  "Control Committee in BOA 2008 - 2011",
];

// boa 2009
export const pressBoaTitle = "BOA 2009 Painted Patterns";
export const pressBoaText = [
  "Astrid Slettevold's paintings are based on a predetermined pattern that recurs in all the images she presents at " +
    "Gallery BOA. At the same time, they appear very different through various color combinations. The color choices " +
    "evoke associations that can lead thoughts towards different cultures or more abstract and personal emotions. " +
    "Some of the paintings may evoke thoughts of the safe and familiar. They can remind us of a pillow our grandmother " +
    "had on the sofa or the bedspread at the cabin, and the references will be personal and tied to our individual " +
    "stories. Other paintings are done in colors that evoke distant places and foreign cultures. They may remind us " +
    "of a trip we once took, awaken the old dream of seeing the pyramids, or traveling to the ends of the earth. " +
    "The paintings have titles like 'My Norwegian Ancestors' and 'Persian Passion' emphasizing how the colors can " +
    "evoke both the domestic and the oriental, even though the pattern is identical.",
  "The color choices stem from Astrid Slettevold's interest in vegetation and associations with the scents and " +
    "flavors of different landscapes. The slightly heavy Norwegian colors are a product of our country's resources " +
    "(plant-based dyes and iron oxides from the earth, etc.), while other places may have a different climate with " +
    "different vegetation resulting in entirely different colors. The idea that the colors derived from natural " +
    "resources form mental concepts of mood and temperament in various cultures is an intriguing perspective. Perhaps " +
    "we are also dealing with a self-fulfilling prophecy, where the colors we use in everyday life fill us with energy " +
    "in different ways and influence our collective identity.",
  "'Bemalte mønstre' (Painted Patterns) is a series of paintings where the artist has wanted to shift her focus away " +
    "from form in order to concentrate more on the colors. After establishing a relatively rigid system as her " +
    "foundation, the process is disrupted through experimentation with colors and shades. Paradoxically, the form " +
    "becomes central due to repetition. Even though the organization of the surfaces is done at the beginning of the " +
    "work, it is the choice of colors that conveys the idea of the work's content and characteristics. However, the " +
    "repetitive pattern creates a focus on form that is impossible to avoid. Through the various associations that the " +
    "colors evoke, the form emerges as universal, and one can see both the similarities and differences that " +
    "characterize our visual world with its history and geographically determined possibilities.",
  "Questions and inquiries can be directed to the gallery consultant, Hans E. Thorsen.",
];
export const hasBeenTranslated =
  "This text has been translated from Norwegian.";

export const pressClaraTitle = "Clara Gallery 2014";
export const pressClaraLink =
  "https://www.dagsavisen.no/demokraten/nyheter/2014/10/10/astrids-farger-pa-galleri-clara/";

export const pressHolmsbueneTitle = "Holmsbuene Gallery 2017";
export const pressHolmsbueneLink =
  "http://holmsbustuene.no/wp-content/uploads/2017/01/Webpresentasjon-Astrid-Slettevold.pdf";

export const pressSandbekkstuaTitle = "Sandbekkstua Gallery 2019";
export const pressSandbekkstuaLink =
  "https://www.rb.no/astrid-slettevold-til-sandbekkstua/o/5-43-1046507";

export const pressWendaTitle = "Wenda Gallery 2019";
export const pressWendaLink =
  "https://kandusi.no/astrid-slettevold-stiller-ut-pa-galleri-wenda/";

export const pressEidsfossTitle = "Gamle Eidsfoss Gallery 2022";
export const pressEidsfossLink =
  "https://www.facebook.com/photo/?fbid=8265748823450174&set=a.584954408196359";

export const contactTitle = "Contact";
export const contactText = [
  "Feel free to contact me if you wish to have a closer look at any of the paintings!",
  "I live in the center of Oslo, and you are welcome to visit my studio.",
  "Also feel free to have a look at my social media pages found in the page footer.",
];
export const phoneNumberTitle = "Phone number";
export const phoneNumberText = "+47 952 01 020";
export const emailTitle = "Email";
export const emailText = "astslet@gmail.com";
